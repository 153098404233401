import { LitElement, TemplateResult, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { consume } from '@lit/context';
import { Task } from '@lit/task';

import 'shared/comments/comments-area';

import { CommentPaginatedResponse } from 'schema/comment/comment-schema';
import { CustomerDetailsContext, customerDetailsContext } from '../context/customer-details-context';
import { CustomerApi, customerApiContext } from 'context/api/customer/customer-api-context';

@customElement('customer-details-comments')
class CustomerDetailsComments extends LitElement {
    @consume({ context: customerApiContext })
    customerApi?: CustomerApi;

    @consume({ context: customerDetailsContext })
    customerDetails: CustomerDetailsContext = null;

    @state()
    _value: string | null = null;

    @state()
    _comments: CommentPaginatedResponse['list'] = [];

    _task = new Task(this, {
        task: async ([]) => {
            await this.load();
        },
        args: () => [] as const,
    });

    private async load(): Promise<void> {
        if (!this.customerApi || !this.customerDetails) {
            return;
        }

        const { list } = await this.customerApi.getCommentsForId(this.customerDetails?.customerId);
        this._comments = list;
    }

    private async onAdd(event: any): Promise<void> {
        if (!this.customerApi || !this.customerDetails) {
            return;
        }

        await this.customerApi.addComment({
            customerId: this.customerDetails?.customerId,
            comment: event.detail.value ?? '',
        });
        await this.load();
    }

    private async onEdit(event: any): Promise<void> {
        if (!this.customerApi || !this.customerDetails) {
            return;
        }
        await this.customerApi.editComment({
            comment: event.detail.value ?? '',
            commentId: event.detail.commentId,
            customerId: this.customerDetails?.customerId,
        });
        await this.load();
    }

    private async onReply(event: any): Promise<void> {
        if (!this.customerApi || !this.customerDetails) {
            return;
        }
        const data = await this.customerApi.replyToComment({
            customerId: this.customerDetails?.customerId,
            comment: event.detail.value ?? '',
            parentId: event.detail.parentId,
        });

        if (!data) {
            return;
        }
        await this.load();
    }

    private async onDelete(event: any): Promise<void> {
        if (!this.customerApi || !this.customerDetails) {
            return;
        }
        const data = await this.customerApi.removeComment({
            customerId: this.customerDetails?.customerId,
            commentId: event.detail.value,
        });
        if (!data) {
            return;
        }
        await this.load();
    }

    render(): TemplateResult {
        return html`
            <comments-area
                .comments="${this._comments}"
                @add="${this.onAdd}"
                @edit="${this.onEdit}"
                @reply="${this.onReply}"
                @delete="${this.onDelete}"
            >
                <div class="grid-vertical gap-05">
                    <pli-text as="h2" variant="h2">Comments</pli-text>
                    <pli-text as="p">Share your notes and thought process</pli-text>
                </div>
            </comments-area>
        `;
    }
}
