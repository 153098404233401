import { LitElement, TemplateResult, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { consume } from '@lit/context';
import { Task } from '@lit/task';
import { nameFormatter } from 'utils/name-formatter';
import { useTotalPagesCount } from 'utils/use-total-pages-count';

import '../../pli/pli-card';
import '../../pli/pli-search-form';
import '../../pli/pli-skeleton';
import '../../pli/pli-text';
import '../../pli/pli-table';
import '../../pli/pli-pagination';

import { baseStyles, gridStyles } from 'pli';
import { clientContext, HttpClient } from 'context/client-context';
import { SortOrder } from 'controllers/storage-order-controller';
import { defineHeaderItems } from '../../pli/pli-table';
import { StorageHandler } from 'storage-handler';
import {
    CustomerCompactPaginatedResponse,
    CustomerCompactResponse,
    CustomerCompactSortableField,
} from 'schema/customer-schema';
import { SortableOrder } from 'schema/pagination/pagination-schema';
import { customerApiContext, CustomerApi } from 'context/api/customer/customer-api-context';

@customElement('customers-overview')
class CustomersOverview extends LitElement {
    @consume({ context: clientContext, subscribe: true })
    @property({ attribute: false })
    client: HttpClient | undefined;

    @consume({ context: customerApiContext })
    customerApi: CustomerApi | undefined;

    static styles = [baseStyles, gridStyles];

    @state()
    _search = '';
    @state()
    _page = 1;
    @state()
    _order: SortableOrder | null = null;
    @state()
    _field: CustomerCompactSortableField | null = null;

    @state()
    _total = 0;

    headerItems = defineHeaderItems({
        Id: {
            sortField: null,
            columnSpan: 2,
        },
        'Customer type': {
            sortField: null,
            columnSpan: 2,
        },
        'Identification number': {
            sortField: 'Identification',
            columnSpan: 2,
        },
        Customer: {
            sortField: 'Name',
            columnSpan: 6,
        },
    });

    private _task = new Task(this, {
        task: async ([page, field, order, search]) => {
            const data = await this.customerApi?.getAll({ page: { no: page }, sort: { field, order: order }, search });
            return data;
        },
        args: () => [this._page, this._field, this._order, this._search] as const,
    });

    connectedCallback(): void {
        super.connectedCallback();

        const stored = StorageHandler.getFromStorage('customers-list-order');
        if (stored !== null) {
            (this._order = stored.sortOrder as SortOrder), (this._field = stored.field as CustomerCompactSortableField);
        }
    }

    private _renderItem(item: CustomerCompactResponse): TemplateResult {
        return html`
            <tr>
                <td>
                    <a data-link="navigate" href="customers/${item.customerId}">
                        <strong>${item.customerId}</strong>
                    </a>
                </td>
                <td>${item.customerType ?? ''}</td>
                <td>${item.identification ?? ''}</td>
                <td>${item.name}</td>
            </tr>
        `;
    }

    private _paginationHandler(event: CustomEvent) {
        this._page = event.detail.page;
    }

    private _sort(event: CustomEvent) {
        this._field = event.detail.field;
        this._order = event.detail.order;
    }

    private search(event: CustomEvent) {
        this._search = event.detail.value;
        event.stopPropagation();
    }

    renderResult(data: CustomerCompactPaginatedResponse | undefined) {
        if (!data) {
            return null;
        }

        const { isPaginationEnabled } = useTotalPagesCount(this._total);

        return html`
            <div class="grid-vertical gap-2">
                <pli-table
                    @sort="${this._sort}"
                    .headerItems="${this.headerItems}"
                    .items="${data.list}"
                    .renderTemplate="${this._renderItem}"
                    sortOrderKey="customers-list-order"
                >
                </pli-table>
                ${isPaginationEnabled
                    ? html`<pli-pagination
                          @pagination-changed="${this._paginationHandler}"
                          page="${this._page}"
                          total="${this._total}"
                      ></pli-pagination>`
                    : null}
            </div>
        `;
    }

    render() {
        return html`
            <div class="grid-vertical gap-1">
                <pli-card>
                    <div class="grid">
                        <div class="col-span-4">
                            <pli-search-form
                                @search="${this.search}"
                                placeholder="Name, Passport..."
                                value="${this._search}"
                            ></pli-search-form>
                        </div>
                    </div>
                </pli-card>
                <pli-card>
                    <div class="grid">
                        <div class="col-span-8">
                            <pli-text variant="h2" as="h2">Customers</pli-text>
                        </div>
                    </div>
                    ${this._task.render({
                        pending: () =>
                            html`<pli-table
                                .loading="${true}"
                                .headerItems="${this.headerItems}"
                                loadingColsCount="${this.headerItems.length}"
                                loadingRowsCount="${10}"
                            ></pli-table>`,
                        complete: (data) => this.renderResult(data),
                    })}
                </pli-card>
            </div>
        `;
    }
}
