import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { customerDetailsContext } from '../context/customer-details-context';
import { CustomerDetailsMapped, CustomerKyc, kycValueType } from 'schema/customer-details-schema';
import { consume } from '@lit/context';
import { styles } from 'pli/styles';

import '../../../pli/pli-card';
import '../../../pli/pli-text';
import '../../../pli/pli-icon';
import '../../../pli/pli-disclosure';

import { getFormattedDate } from 'utils/datetime-formatter';
import { when } from 'lit/directives/when.js';

@customElement('customer-details-profile')
class CustomerDetailsProfile extends LitElement {
    static styles = [
        styles.grid,
        styles.flex,
        styles.padding,
        styles.divider,
        css`
            pli-disclosure {
                display: block;
            }
        `,
    ];

    @consume({ context: customerDetailsContext })
    customerDetails: CustomerDetailsMapped | null = null;

    render() {
        const { customerDetails } = this;

        if (!customerDetails) {
            return null;
        }

        const renderTemplate = (item: { label: string; value: unknown }) =>
            html`${when(
                item.value,
                () =>
                    html`<div class="col-span-2">
                            <pli-text><strong>${item.label}</strong></pli-text>
                        </div>
                        <div class="col-span-10">
                            <pli-text>${item.value}</pli-text>
                        </div>`,
            )}`;

        const informationPrimaryMapped = [
            { label: 'Full name', value: customerDetails.name },
            { label: 'Identification number', value: customerDetails.identification },
            { label: 'Birthdate', value: customerDetails.birthdate },
            { label: 'Customer Id', value: customerDetails.customerId },
        ];

        const mapKycValue = (item: CustomerKyc): string => {
            switch (item.type) {
                case kycValueType.Enum.Date:
                    return getFormattedDate(new Date(item.value));
                case kycValueType.enum.Boolean:
                    return item.value;
                case kycValueType.enum.NumberRange:
                    return `${item.from} - ${item.to}`;
                case kycValueType.enum.DateRange:
                    const from = item.from ?? '';
                    const to = item.to ?? '';
                    return `${getFormattedDate(new Date(from))} - ${getFormattedDate(new Date(to))}`;
                default:
                    return item.value;
            }
        };

        const mapKYCDisplayedValues = (items: CustomerKyc[]) =>
            items.map((item) => ({
                label: item.name,
                value: mapKycValue(item),
            }));

        const informationSecondaryMapped = mapKYCDisplayedValues(customerDetails.information);
        const kycMapped = mapKYCDisplayedValues(customerDetails.kyc);

        return html` <pli-card>
            <div class="divider-y grid-vertical gap-1">
                <pli-disclosure open placeholder="Information">
                    <div class="grid-vertical divider-y">
                        <div class="grid gap-2 py-2">
                            ${informationPrimaryMapped.map((item) => renderTemplate(item))}
                        </div>
                        <div class="grid gap-2 py-2">
                            ${informationSecondaryMapped.map((item) => renderTemplate(item))}
                        </div>
                    </div>
                </pli-disclosure>
                <div class="pt-2">
                    <pli-disclosure open placeholder="KYC">
                        <div class="grid-vertical">
                            <div class="grid gap-2 py-2">${kycMapped.map((item) => renderTemplate(item))}</div>
                        </div>
                    </pli-disclosure>
                </div>
            </div>
        </pli-card>`;
    }
}
