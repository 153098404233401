import { AlertModel } from 'alert-model';
import { HttpClient, json } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { CustomerModel } from 'customer-model';
import { ErrorService } from 'error/error-service';
import { OData } from 'utils/odata';
import { Router, RouterConfiguration, activationStrategy } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import { OpenIdConnectRoles } from 'aurelia-open-id-connect';
import { cleanParamsObject } from 'utils/params';

@autoinject
export class CustomerDetails {
    httpClient: HttpClient;
    json: any;
    router: Router;
    customer: CustomerModel;
    errorService: ErrorService;

    relatedAlerts: AlertModel[];
    relatedCases: Case[];

    id: string;

    constructor(httpClient: HttpClient, router: Router, errorService: ErrorService) {
        this.httpClient = httpClient;
        this.router = router;
        this.errorService = errorService;
    }

    async activate(params) {
        params = cleanParamsObject(params);
        await this.load(params.id);
        await this.loadRelatedCases();

        this.id = params.id;
    }

    public configureRouter(config: RouterConfiguration, router: Router) {
        config.map([
            {
                route: '',
                name: 'customer-list-case-alerts',
                moduleId: PLATFORM.moduleName('./list/list'),
                settings: { roles: [OpenIdConnectRoles.Authenticated] },
                activationStrategy: activationStrategy.invokeLifecycle,
            },
            {
                route: '/cases/:id',
                name: 'customer-case-details',
                moduleId: PLATFORM.moduleName('./case-details/case-details'),
                settings: { roles: [OpenIdConnectRoles.Authenticated] },
                activationStrategy: activationStrategy.invokeLifecycle,
            },
            {
                route: '/alerts/:id',
                name: 'customer-alert-details',
                moduleId: PLATFORM.moduleName('./alert-details/alert-details'),
                settings: { roles: [OpenIdConnectRoles.Authenticated] },
                activationStrategy: activationStrategy.invokeLifecycle,
            },
        ]);

        this.router = router;
    }

    async load(id: string) {
        try {
            let response: any = await this.httpClient.get(`customers/${id}`);
            let json = await response.json();

            this.json = JSON.stringify(json, null, 2);
            this.customer = <CustomerModel>json;
        } catch (error) {
            this.errorService.showErrorDialog(error.message);
            this.router.navigateToRoute('customers', {});
        }
    }

    async loadRelatedAlerts() {
        let filter = OData.filter(`CustomerId eq '${this.customer.customerId}' and Status ne 'Closed'`);

        let response: any = await this.httpClient.get(`alerts`);
        let json = await response.json();

        this.relatedAlerts = <AlertModel[]>json.value;
    }

    async loadRelatedCases() {
        let filter = OData.filter(`CustomerId eq '${this.customer.customerId}'`);

        let response: any = await this.httpClient.get(`cases`);
        let json = await response.json();

        this.relatedCases = <Case[]>json.value;
    }
}

class Case {}
